import React from "react";
import ReactLoading from "react-loading";
import styled from "tachyons-components";

export function Loading(props) {
	let msg = props.message;
	return (
		<Section>
			<Article style={{ marginTop: "100px" }}>
				<ReactLoading
					type="spokes"
					color="#214b71"
					height={"60px"}
					width={"60px"}
				/>
				<Prop style={{ marginTop: "20px" }}>{msg}</Prop>
			</Article>
		</Section>
	);
}

const Section = styled("div")`
flex flex-wrap content-center justify-center w-100 h-100`;

const Article = styled("div")`
w-50 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

const Prop = styled("h3")`
f5 f4-ns mb0`;
