import React, { useState, useEffect } from "react";

import { useAuth } from "../authentication/AuthProvider";

import { Accordion } from "./../components/Accordion";
import { NavLink } from "react-router-dom";

export function Sidebar() {
	const [state, setState] = useState({
		tags: [],
		loading: false,
	});

	const auth = useAuth();
	useEffect(() => {
		async function fetchTags() {
			auth.apiGet("api/categories/gettags").then((result) => {
				setState({ tags: result, loading: false });
			});
		}

		fetchTags();
	}, [auth]);

	return (
		<nav className="sidenavAccordion sb-sidenav accordion sb-sidenav-dark">
			<div className="sb-sidenav-menu">
				<div className="nav">
					<div className="sb-sidenav-menu-heading">Intelligence</div>

					<hr />
					<Accordion
						title="Studies"
						icon="fa-book-open"
						collapsed={false}
					>
						{!auth.user.activeSubscription && (
							<NavLink to="/studies" className={"nav-link"}>
								Your studies
							</NavLink>
						)}
						<Accordion title="All studies" collapsed={false}>
							<NavLink to="/catalog/" className={"nav-link pb-0"}>
								All topics
							</NavLink>
							{state.tags != null &&
								state.tags.map((tag) => (
									<NavLink
										to={"/catalog/" + tag.id}
										className={"nav-link pb-0"}
										key={tag.id}
									>
										{tag.name}
									</NavLink>
								))}
						</Accordion>
					</Accordion>
				</div>
			</div>
			<div className="sb-sidenav-footer">
				<div className="small">Logged in as:</div>
				{auth.user.firstname + " " + auth.user.lastname}
			</div>
		</nav>
	);
}
