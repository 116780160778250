import React from "react";

import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@awesome.me/kit-524d1d64d7/icons/classic/solid";
import {
	faFilePdf,
	faMagnifyingGlassChart,
	faChartScatterBubble,
	faBuildingMagnifyingGlass,
	faChartMixed,
	faEarthAmericas,
	faChartTreeMap,
	faChartCandlestick,
} from "@awesome.me/kit-524d1d64d7/icons/sharp-duotone/solid";

export function StudyDetailsActions(props) {
	let study = props.study;
	let hasStudyFiles = props.hasStudyFiles;
	let studyPage = "/studies/" + study.catalogID;
	let forecastage = "/StudyForecast/" + study.catalogID;

	return (
		<div className="row mt-4">
			{hasStudyFiles.hasPDFs === true ? (
				<div
					className="col-xl-3 col-md-4"
					onClick={() => window.open(studyPage, "_blank")}
				>
					<div className="card bg-light mb-4 h-75">
						<div className="card-body">
							<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
								{" "}
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
							<FontAwesomeIcon
								icon={faFilePdf}
								className="fa-3x float-start me-3 mt-2 mb-3"
							/>
							<h5 className="card-title"> Full Study</h5>
							<p>View or Download full study</p>
						</div>
					</div>
				</div>
			) : null}

			{/* <div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faMagnifyingGlassChart}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title">
							{" "}
							Technology Overview &amp; Analysis
						</h5>
						<p>Current &amp; Future Technologies</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faChartScatterBubble}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title">
							{" "}
							Market Overview &amp; Analysis
						</h5>
						<p>Competitive Landscape & Market Dynamics</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faBuildingMagnifyingGlass}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title"> Competitor Analysis</h5>
						<p>Company Profiles & Insights</p>
					</div>
				</div>
			</div> */}
			{hasStudyFiles.hasForecast === true ? (
				<Link
					className="col-xl-3 col-md-4 link-dark text-decoration-none"
					to={forecastage}
				>
					<div className="card bg-light mb-4 h-75">
						<div className="card-body">
							<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
								{" "}
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
							<FontAwesomeIcon
								icon={faChartMixed}
								className="fa-3x float-start me-3 mt-2 mb-3"
							/>{" "}
							<h5 className="card-title"> Forecast</h5>
							<p>
								Global and Segmented Forecasts Data &amp;
								Visualization
							</p>
						</div>
					</div>
				</Link>
			) : null}
			{/* <div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faEarthAmericas}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title"> Country Analysis</h5>
						<p>Programs, Key Players, and Inventory</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faChartTreeMap}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title">
							{" "}
							Market Maps & Supply Chain
						</h5>
						<p>Visualize industry connections</p>
					</div>
				</div>
			</div>
			<div className="col-xl-3 col-md-4">
				<div className="card bg-light mb-4 h-75">
					<div className="card-body">
						<div className="btn btn-success btn-sm stretched-link float-end ms-2 mb-4">
							{" "}
							<FontAwesomeIcon icon={faArrowRight} />
						</div>
						<FontAwesomeIcon
							icon={faChartCandlestick}
							className="fa-3x float-start me-3 mt-2 mb-3"
						/>{" "}
						<h5 className="card-title"> Products</h5>
						<p> Study Related Products including CRM and TRL</p>
					</div>
				</div>
			</div> */}
		</div>
	);
}
