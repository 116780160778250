import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAuth } from "../authentication/AuthProvider";
import { PdfViewer } from "../components/PdfViewer";
import { Loading } from "../components/Loading";

export function Study() {
	let { catalogID } = useParams();

	const [study, setStudy] = useState(null);
	const [loading, setLoading] = useState(true);
	const [blobURL, setBlobURL] = useState("");
	const [fileName, setFileName] = useState("MarketForecast-Study.pdf");
	const [fileId, setFileId] = useState("");

	const auth = useAuth();

	useEffect(() => {
		const formFileName = (study) => {
			let fName = "MarketForecast - " + study.title;
			let user = auth.user;
			if (user && user.firstname && user.lastname) {
				fName += " - " + user.firstname + " " + user.lastname;
			}

			fName += ".pdf";

			return fName;
		};

		async function fetchDetails() {
			const endpoint = "api/study/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}
				setStudy(json);

				const fName = formFileName(json);
				setFileName(fName);
			});
		}

		async function fetchStudy() {
			const studyEndpoint = "api/study/Download/";
			if (!catalogID) {
				// handle this
			}

			let url = studyEndpoint + catalogID;
			auth.apiBlobPlusData(url).then((res) => {
				setBlobURL(res.fileURL);
				setFileId(res.fileId);
				setLoading(false);
			});
		}

		fetchDetails();
		fetchStudy();
	}, [auth, catalogID]);

	return !loading ? (
		<div>
			<PdfViewer
				fileName={fileName}
				fileURL={blobURL}
				catalogID={catalogID}
				fileId={fileId}
			/>
		</div>
	) : (
		<div>
			{study != null ? (
				<Loading
					message={
						"We are preparing your personal copy of " + study.title
					}
				/>
			) : (
				<Loading />
			)}
		</div>
	);
}
