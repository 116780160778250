import { useAuth } from "../authentication/AuthProvider";

export const useTrackUserAction = () => {
	const auth = useAuth();

	const trackUserAction = async (
		actionId,
		catalogId = null,
		tagId = null,
		productFileId = null
	) => {
		auth.apiPost("/api/tracker/Track", {
			url: window.location.pathname,
			actionId: actionId,
			catalogId: catalogId,
			tagId: tagId,
			productFileId: productFileId,
			// userActionId, userActionEntityId, etc.
		});
	};

	return trackUserAction;
};
