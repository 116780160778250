import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

export const AnyChartFigure1Versus = ({
	years,
	data,
	segmentationItems,
	total,
	valueDisplayOption,
	segmentation1ItemsName,
	topicName,
	segmentationName2,
}) => {
	const anyChartFigure1VersusRef = useRef(null);

	useEffect(() => {
		const chart = anychart;

		// construct data for set
		const totalProcessed = [...total];
		totalProcessed.shift();
		totalProcessed.pop();

		// format ToParse
		for (let i = 0; i < totalProcessed.length; i++) {
			const num = parseFloat(totalProcessed[i]);
			totalProcessed[i] = isNaN(num) ? totalProcessed[i] : num;
		}

		let forSetData = [];

		years.forEach((year, index) => {
			let array = [year, ...data[index], totalProcessed[index]];
			forSetData.push(array);
		});

		// Set Data
		let dataSet = chart.data.set(forSetData);

		// create Bar Chart
		let columnChart = chart.column();

		// APPLY THEME
		chart.theme("marketForecast");

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		// CREDITS
		const currentYear = new Date().getFullYear();
		columnChart
			.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		// force chart to stack values by Y Scale
		columnChart.yScale().stackMode("value");

		// TITLE
		columnChart
			.title()
			.enabled(true)
			.useHtml(true)
			.padding([0, 0, 10, 0])
			.text(
				//
				// `<span style="font-size: 16px;font-weight:bold;">${segmentation1ItemsName} Market Forecast to ${ years[years.length -1 ]} by ${segmentationName2}</span>` +
				`<span style="font-size: 16px;font-weight:bold;">${segmentation1ItemsName} by ${segmentationName2} </span>` +
					'<br/><span style="color:#929292; font-size: 12px;">' +
					`Market Forecast ${years[0]} - ${
						years[years.length - 1]
					}</span>`
			);

		// SET CHART MARGIN SETTINGS
		columnChart.padding(20, 5, 5, 5);

		// GRID SETTINGS
		columnChart.yGrid(true).xGrid(false).xMinorGrid(false).yMinorGrid(true);

		// SET CHART LEGEND SETTINGS
		columnChart.legend().enabled(true).padding({ top: 10, bottom: 30 });
		columnChart.legend().position("bottom");
		columnChart.legend().itemsLayout("horizontal-expandable");

		columnChart
			.yAxis(0)
			.title(`Market size in US$ ${valueDisplayOption}`)
			.title()
			.padding(20)
			.fontColor("#385D8A");

		columnChart
			.yAxis(0)
			.labels()
			.format(function () {
				return anychart.format.number(this.value, {
					groupsSeparator: ",",
				});
			});

		// HELPER FUNCTION TO SETUP LABEL SETTINGS FOR ALL SERIES
		const setupSeriesLabels = (series, item) => {
			series.name(item.description).stroke("1 #fff 1");
			series.hovered().stroke("1 #fff 1");

			series.normal().stroke("#ffffff", 1, "", "");
			series.hovered().stroke("#ffffff", 1, "", "");
			series.selected().stroke("#ffffff", 1, "", "");

			series.normal().fill(function () {
				return this.sourceColor + " 1"; // Mantener el color con opacidad 1
			});

			series.hovered().fill(function () {
				return this.sourceColor + " 0.8"; // Mantener el color con opacidad 0.8
			});

			series.selected().fill(function () {
				return this.sourceColor + " 0.6"; // Mantener el color con opacidad 0.6
			});

			series.legendItem().text(item.name);
		};

		// create Series with mapped Data
		// set dinamic mapData , create dinamic series

		segmentationItems.forEach((item, index) => {
			const segmSeriesData = dataSet.mapAs({ x: 0, value: index + 1 });
			const series = columnChart.column(segmSeriesData);
			setupSeriesLabels(series, item);
		});

		// SET GLOBAL TOOLTIP styles
		columnChart.tooltip().title().fontColor("#FFFFFF");
		columnChart.tooltip().title().fontSize(14);
		columnChart.tooltip().fontColor("#FFFFFF");
		columnChart.tooltip().fontSize(12);
		columnChart.tooltip().padding(10);

		// Customize the tooltip title and content
		columnChart
			.tooltip()
			.titleFormat(function () {
				return "Market Forecast: " + this.points[0].x;
			})
			.format(function () {
				return (
					this.seriesName +
					" : " +
					anychart.format.number(this.value, {
						groupsSeparator: ",",
						decimalSeparator: ".",
					}) +
					` US$ ${valueDisplayOption}`
				);
			});

		// SET interactivity hover
		columnChart.interactivity().hoverMode("by-y");
		columnChart.tooltip().valuePrefix("$").displayMode("union");

		// Marker
		const totalSeriesData = dataSet.mapAs({
			x: 0,
			value: forSetData[0].length - 1,
		});
		const totalSeriesForecast = columnChart.marker(totalSeriesData);
		totalSeriesForecast.name("Total");
		totalSeriesForecast.normal().type("circle");

		// Add labels to totalSeriesForecast with format
		totalSeriesForecast
			.labels()
			.enabled(true)
			.format(function () {
				return anychart.format.number(this.value, {
					groupsSeparator: ",",
				});
			})
			.anchor("center-bottom")
			.offsetY(10)
			.padding(5)
			.background()
			.enabled(true)
			.fill("#FFFFFF");

		columnChart.contextMenu().itemsFormatter(function (items) {
			delete items["about"];
			delete items["share-with"];

			return items;
		});

		// SET container id for the chart
		columnChart.container(anyChartFigure1VersusRef.current).draw();
		chart.exports.filename(
			`VS01_${segmentation1ItemsName}_${topicName}_Market_Forecast_to_${
				years[years.length - 1]
			}_by_${segmentationName2}`
		);

		return () => {
			columnChart.dispose();
		};
	}, [
		years,
		data,
		segmentationItems,
		total,
		valueDisplayOption,
		segmentation1ItemsName,
		topicName,
		segmentationName2,
	]);

	return (
		<div
			ref={anyChartFigure1VersusRef}
			style={{ width: "600px", height: "450px" }}
		>
			{" "}
		</div>
	);
};
