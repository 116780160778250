import React, { useState, useEffect } from "react";
import { AnyChartFigure1Versus } from "./components/AnyChartFigure1Versus";
import { AnychartSimplifiedPieChart } from "../SingleSegmentationOutput/components/AnychartSimplifiedPieChart";

export const VersusSegmentationOutput = ({
	output,
	topicName,
	arrayDesc,
	valueDisplayOption,
	decimalsOption,
}) => {
	//  outputViews for table

	// assign output useStates
	const [segmentationVersusTitles, setSegmentationVersusTitles] = useState(
		[]
	);
	const [segmentationTitles, setSegmentationTitles] = useState([]);
	const [segmentationDesc, setSegmentationDesc] = useState([]);
	const [headerTable, setHeaderTable] = useState([]);
	const [segmentationItemValues, setSegmentationItemValues] = useState([]);
	const [segmentation1, setSegmentation1] = useState([]);
	const [segmentation2, setSegmentation2] = useState([]);

	// assign output to Chart useStates
	const [toChartData, setToChartData] = useState([]);
	const [toChartYears, setToChartYears] = useState([]);
	const [toChartSegmItems, setToChartSegmItems] = useState([]);

	// assign Output To FigureVersusChart2 useStates
	const [toFigureVersus2ChartDataYear, setToFigureVersus2ChartDataYear] =
		useState([]);
	const [toFigureVersus2ChartFirstYear, setToFigureVersus2ChartFirstYear] =
		useState(null);
	const [toFigureVersus2ChartLastYear, setToFigureVersus2ChartLastYear] =
		useState(null);

	// Get totals
	const [totals, setTotals] = useState([]);

	//
	const [finish, setFinish] = useState(false);

	// sync loading
	const [loadingStep, setLoadingStep] = useState(0);
	const [loadingSteps, setLoadingSteps] = useState(3);
	const [loadingFinish, setLoadingFinish] = useState(false);

	let parentNumber = 0;
	let childNumber = 0;

	// LIFECICLES
	useEffect(() => {
		setFinish(false);
		setLoadingStep(0);
		if (output) {
			assignOutput();
			assignOutputToChart();

			// Second Chart
			assignOutputToFigureVersusChart2();
		}
	}, [output, valueDisplayOption, decimalsOption]);

	useEffect(() => {
		if (segmentationItemValues.length > 0) {
			getTotalRow();
		}
	}, [segmentationItemValues]);

	const getIndex = (value) => {
		if (value === parentNumber) {
			childNumber = childNumber + 1;
			return childNumber;
		} else {
			parentNumber = value;
			childNumber = 1;
			return childNumber;
		}
	};

	const assignOutput = () => {
		let years = [""];
		output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.forEach(
			(item) => years.push(item.year)
		);

		const fyString = years[1].toString();
		const lyString = years[years.length - 1].toString();

		let sumHeader = "∑" + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(sumHeader);

		let cagr = "CAGR " + fyString.slice(-2) + "-" + lyString.slice(-2);
		years.push(cagr);

		setHeaderTable((prevHeaderTable) => [...prevHeaderTable, years]);

		let newSegmentationVersusTitles = [];
		let newSegmentation1 = [];
		let newSegmentation2 = [];
		let newSegmentationItemValues = [];
		let newSegmentationTitles = [];
		let segItemNameGroupLast = [];

		output.forEach((outputItem, index) => {
			let titles = [];
			const segmentationName = outputItem.segmentations[0].name;
			const segmentationName2 = outputItem.segmentations[1].name;

			titles.push([segmentationName + " - " + segmentationName2]);
			newSegmentationVersusTitles.push(titles);

			newSegmentation1.push(segmentationName);
			newSegmentation2.push(segmentationName2);

			const versusValueFinal = [];
			let SegmItemNameGroup = [];

			outputItem.segmentations[0].segmentationItems.forEach((item) => {
				let segmItemName =
					item.regionId != null ? item.region.name : item.name;
				SegmItemNameGroup.push(segmItemName);
				// console.log(segmItemName);

				const versusValue = [];
				item.inverseParent.forEach((item2) => {
					const versusValue2 = [];
					// TODO add comparor here
					let segmItemName = compareAbbreviation(item2.name);
					if (segmItemName.name === null) {
						segmItemName = {
							name: item2.region.name,
							description: null,
						};
					}
					versusValue2.push(segmItemName);

					item2.documentProgramTopic.documentProgramValues.forEach(
						(item3) => {
							versusValue2.push(
								tofixValueOptionDecimate(item3.value)
							);
						}
					);

					let sumArray = [...versusValue2];
					sumArray.shift();
					let sum = toFixDecimate3(
						sumArray.reduce((acc, val) => acc + parseFloat(val), 0)
					);
					versusValue2.push(sum);

					let cagr = item2.documentProgramTopic.cagr
						? item2.documentProgramTopic.cagr.toFixed(1) + "%"
						: "0%";
					versusValue2.push(cagr);

					versusValue.push(versusValue2);
				});
				versusValueFinal.push(versusValue);
			});
			segItemNameGroupLast.push(SegmItemNameGroup);
			newSegmentationItemValues.push(versusValueFinal);
		});

		setSegmentationVersusTitles(newSegmentationVersusTitles);
		setSegmentation1(newSegmentation1);
		setSegmentation2(newSegmentation2);
		setSegmentationItemValues(newSegmentationItemValues);
		setSegmentationTitles(segItemNameGroupLast);
	};

	const compareAbbreviation = (abrev) => {
		for (let subArray of arrayDesc) {
			for (let item of subArray) {
				if (item.name === abrev) {
					return item;
				}
			}
		}
		return { name: abrev, description: null };
	};

	const assignOutputToChart = () => {
		let years = [];
		let outputViews = [];

		// outputYears SHOW ARRAY OF YEARS ['2024', '2025', '2026']
		output[0].segmentations[0].segmentationItems[0].inverseParent[0].documentProgramTopic.documentProgramValues.forEach(
			(item) => years.push(item.year)
		);
		outputViews.push(years);
		setToChartYears(outputViews);

		// outputData SHOW ARRAY OF ARRAY FOR SHOWING TO CHART
		let outputData = [];
		output.forEach((item, index) => {
			const versusValueFinal = [];
			item.segmentations[0].segmentationItems.forEach((segmItem) => {
				const versusValue = [];

				segmItem.inverseParent.forEach((item2) => {
					const versusValue2 = [];
					item2.documentProgramTopic.documentProgramValues.forEach(
						(item3) => {
							versusValue2.push(
								tofixValueOptionDecimate(item3.value)
							);
						}
					);
					versusValue.push(versusValue2);
				});
				versusValueFinal.push(versusValue);
			});
			outputData.push(versusValueFinal);
		});

		// outputDataFinalle SHOW ARRAY BY YEARS COUNT SHOW VALUE BY COLUMN
		let outputDataFinalle = [];

		outputData.forEach((output) => {
			let bData = [];
			output.forEach((item) => {
				let outputArray = [];
				outputViews[0].forEach((year, index) => {
					let aData = [];
					item.forEach((data) => {
						aData.push(data[index]);
					});
					outputArray.push(aData);
				});
				bData.push(outputArray);
			});
			outputDataFinalle.push(bData);
		});

		setToChartData(outputDataFinalle);

		// SegmItemsRival
		// SHOW THE SEGMENTATIONITEMS FOR LEGACY ['SEGMENTATION1', 'SEGMENTATION2', 'SEGMENTATION3']
		let segmItemsArray = [];
		output.forEach((item) => {
			let segmItemsRivalNameArray0 = [];
			item.segmentations[0].segmentationItems.forEach((segmItem) => {
				const segmItemsRivalNameArray = [];
				segmItem.inverseParent.forEach((item2) => {
					let versusValue2;
					versusValue2 = {
						name:
							item2.name !== null
								? item2.name
								: item2.region.name,
						description:
							item2.description !== null
								? item2.description
								: item2.region !== null
								? item2.region.name
								: item2.name,
					};
					// item2.regionId != null ? item2.region.name : item2.name;
					segmItemsRivalNameArray.push(versusValue2);
				});
				segmItemsRivalNameArray0.push(segmItemsRivalNameArray);
			});
			segmItemsArray.push(segmItemsRivalNameArray0);
		});

		setToChartSegmItems(segmItemsArray);
	};

	const assignOutputToFigureVersusChart2 = () => {
		for (let index = 0; index < output.length; index++) {
			const versusValueFinalYear = [];

			output[index].segmentations[0].segmentationItems.forEach((si) => {
				let versusValueBySegmentation = [];
				si.inverseParent.forEach((ip) => {
					const inverseSegmItemName =
						ip.name !== null ? ip.name : ip.region.name;

					const inverseSegmItemDescription =
						ip.description !== null
							? ip.description
							: ip.region !== null
							? ip.region.name
							: ip.name;

					const documentProgramValuesLength =
						ip.documentProgramTopic.documentProgramValues.length;

					let valueFirstLastYear = [
						inverseSegmItemName, // InverseSegmItemName
						tofixValueOptionDecimate(
							// firstYearValue
							ip.documentProgramTopic.documentProgramValues[0]
								.value
						),
						tofixValueOptionDecimate(
							// lastYearValue
							ip.documentProgramTopic.documentProgramValues[
								documentProgramValuesLength - 1
							].value
						),
						inverseSegmItemDescription,
					];
					versusValueBySegmentation.push(valueFirstLastYear);
				});
				versusValueFinalYear.push(versusValueBySegmentation);
			});

			setToFigureVersus2ChartDataYear((prevItems) => [
				...prevItems,
				versusValueFinalYear,
			]); // push dataYears to useState
		}

		// push FirstYear
		setToFigureVersus2ChartFirstYear(
			output[0].segmentations[0].segmentationItems[0].inverseParent[0]
				.documentProgramTopic.documentProgramValues[0].year
		);

		let documentProgramValuesLength =
			output[0].segmentations[0].segmentationItems[0].inverseParent[0]
				.documentProgramTopic.documentProgramValues.length - 1;

		// push lastYear
		setToFigureVersus2ChartLastYear(
			output[0].segmentations[0].segmentationItems[0].inverseParent[0]
				.documentProgramTopic.documentProgramValues[
				documentProgramValuesLength
			].year
		);
	};

	const getTotalRow = () => {
		// CLONE DEEP ARRAY
		let segmentationItemValuesClone = JSON.parse(
			JSON.stringify(segmentationItemValues)
		);

		//GET TOTAL
		let newTotals = segmentationItemValuesClone.map((siv) => {
			let a = [];
			siv.forEach((segmItem) => {
				let b = [];
				segmItem.forEach((si) => {
					if (b.length === 0) {
						let totArray = [];
						si.forEach((s, index) => {
							if (index === 0) {
								totArray.push("Total");
							} else {
								if (index !== si.length - 1) {
									if (typeof s !== "number") {
										let parseToNumber = Number(s);
										totArray.push(parseToNumber);
									} else {
										totArray.push(s);
									}
								}
							}
						});
						b.push(totArray);
					} else {
						si.forEach((s, index2) => {
							if (index2 !== 0 && index2 !== si.length - 1) {
								if (typeof s !== "number") {
									let parseToNumber = Number(s);
									b[0][index2] += parseToNumber;
								} else {
									b[0][index2] += s;
								}
							}
						});
					}
				});

				// Transform data to fixed decimate
				let c = [];
				const d = b[0].map((item, index) => {
					if (index !== 0) {
						return toFixDecimate3(item);
					} else {
						return item;
					}
				});

				// Add CAGR to total row
				const cagrValue = calculateCAGR(d).toFixed(1) + "%";
				d.push(cagrValue);

				// Add D array to c
				c.push(d);

				// Push to a array
				a.push(c);
			});
			return a;
		});

		setTotals(newTotals);

		setFinish(true);
	};

	useEffect(() => {
		if (loadingStep < loadingSteps) {
			const timer = setTimeout(() => {
				setLoadingStep((prevStep) => prevStep + 1);
			}, 100);
			return () => clearTimeout(timer);
		} else {
			setLoadingFinish(true);
		}
	}, [loadingStep]);

	// HELPERS

	const convertValueDisplayOption = (data) => {
		if (valueDisplayOption === "billions") {
			return data / 1000;
		} else {
			return data;
		}
	};

	const toFixDecimate3 = (cell) => {
		if (decimalsOption === "0.") {
			if (Number.isInteger(cell)) {
				return cell;
			} else {
				return Math.round(cell);
			}
		}
		if (decimalsOption === ".0") {
			return roundTo(cell, 1).toFixed(1);
		}
		if (decimalsOption === ".00") {
			return roundTo(cell, 2).toFixed(2);
		}
		if (decimalsOption === ".000") {
			return roundTo(cell, 3).toFixed(3);
		}
	};

	const tofixValueOptionDecimate = (data) => {
		const valueDisplay = convertValueDisplayOption(data);
		return toFixDecimate3(valueDisplay);
	};

	const roundTo = (num, decimals) => {
		let factor = Math.pow(10, decimals);
		return Math.round(num * factor) / factor;
	};

	const formatNumber = (num) => {
		if (num) {
			// split int and decimals of number
			let [int, decimal] = num.toString().split(".");
			//format int with ,
			int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return decimal ? `${int}.${decimal}` : int;
		} else {
			return num;
		}
	};

	const calculateCAGR = (values) => {
		let val = [...values];
		val.shift();
		val.pop();

		let initialValue = val[0];
		let finalValue = val[val.length - 1];
		let n = val.length - 1;

		const cagr = Math.pow(finalValue / initialValue, 1 / n) - 1;
		return cagr ? cagr * 100 : 0;
	};

	const compareFYFL = (fy, fl) => {
		const fyTotal = fy.reduce((acc, value) => acc + parseFloat(value), 0);
		const flTotal = fl.reduce((acc, value) => acc + parseFloat(value), 0);

		const totalFY = fyTotal;
		const totalLY = flTotal;

		return totalFY > totalLY ? true : false;
	};

	return (
		<>
			{finish ? (
				<>
					{/* <h1>Versus Group of graphics</h1> */}

					{segmentationItemValues.map(
						(segmentation, segmentationIndex) => (
							<div key={"segmentation" + segmentationIndex}>
								<hr />
								<div className="col-12 col-lg-8 mt-5">
									<h4 id={`chapter${segmentationIndex + 2}`}>
										{segmentationIndex + 2}. {topicName}{" "}
										Market{" "}
										{
											output[segmentationIndex]
												.segmentations[0].name
										}{" "}
										by{" "}
										{
											output[segmentationIndex]
												.segmentations[1].name
										}
									</h4>
								</div>

								{segmentation.map((segm, segmIndex) => (
									<div key={"segm" + segmIndex}>
										{/* TABLES */}
										<div className="col-12 col-lg-8 mt-2">
											<hr />
											<h6>
												Table {segmentationIndex + 2}.
												{segmIndex + 1}{" "}
												{
													segmentationTitles[
														segmentationIndex
													][segmIndex]
												}{" "}
												Market Forecast to{" "}
												{
													toChartYears[0][
														toChartYears[0].length -
															1
													]
												}{" "}
												by{" "}
												{
													segmentation2[
														segmentationIndex
													]
												}{" "}
												US$ {valueDisplayOption}
											</h6>

											{loadingStep >= 1 ? (
												<table className="table caption-top table-bordered">
													<thead>
														<tr className="bg-light text-dark">
															{headerTable[0].map(
																(
																	header,
																	headerIndex
																) =>
																	headerIndex ===
																	0 ? (
																		<th
																			key={
																				"headerIndex " +
																				headerIndex
																			}
																		>
																			{/* {{ this.segmentationTitles[index1][index] }} US$ {{this.valueDisplayOption}} */}

																			{/* {
                                                                                    segmentationTitles[segmentationIndex][segmIndex] + ' US$ ' +valueDisplayOption  
                                                                                } */}
																		</th>
																	) : (
																		<th
																			className="text-end"
																			scope="col"
																			key={
																				"headerIndex " +
																				headerIndex
																			}
																		>
																			{
																				header
																			}
																		</th>
																	)
															)}
														</tr>
													</thead>

													<tbody>
														{/* {console.log(segm)} */}
														{segm.map(
															(row, rowIndex) => (
																<tr
																	key={
																		"rowIndex" +
																		rowIndex
																	}
																>
																	{row.map(
																		(
																			cell,
																			cellIndex
																		) => (
																			<td
																				className={
																					cellIndex ===
																					0
																						? "text-start"
																						: "text-end"
																				}
																				key={
																					"cellIndex" +
																					cellIndex
																				}
																			>
																				{cellIndex ===
																				0 ? (
																					<>
																						{cell.description ===
																						null ? (
																							cell.name
																						) : (
																							<abbr
																								title={
																									cell.description
																								}
																							>
																								{
																									cell.name
																								}
																							</abbr>
																						)}
																					</>
																				) : (
																					formatNumber(
																						cell
																					)
																				)}
																				{/* {formatNumber(
																					cell
																				)} */}
																			</td>
																		)
																	)}
																	{/* {row} {rowIndex} */}
																</tr>
															)
														)}
													</tbody>
													<tfoot>
														<tr className="bg-light">
															{
																// console.log(totals[segmentationIndex][segmIndex][0])
																totals[
																	segmentationIndex
																][
																	segmIndex
																][0].map(
																	(
																		totalVersus,
																		totalVersusIndex
																	) => (
																		<td
																			className="text-end"
																			key={
																				"totalVersusIndex" +
																				totalVersusIndex
																			}
																		>
																			{totalVersusIndex ===
																			0 ? (
																				<strong>
																					{
																						totalVersus
																					}
																				</strong>
																			) : (
																				formatNumber(
																					totalVersus
																				)
																			)}
																		</td>
																	)
																)
															}
														</tr>
													</tfoot>
												</table>
											) : (
												<h3>
													{/* TODO CHANGE LOADING */}
													<div>
														<div className="row mt-1">
															<div
																className="spinner-border gutter-none m-5"
																role="status"
															>
																<span className="visually-hidden">
																	Loading...
																</span>
															</div>
														</div>
														<div className="row">
															<span className="text-primary">
																Loading data...
															</span>
														</div>
													</div>
												</h3>
											)}
										</div>
										{/* CHARTS  */}
										<div style={{ marginTop: "3rem" }}>
											<hr />
											<h6>
												{" "}
												Figure {segmentationIndex + 2}.
												{getIndex(segmentationIndex)}{" "}
												{
													segmentationTitles[
														segmentationIndex
													][segmIndex]
												}{" "}
												Market Forecast to{" "}
												{
													toChartYears[0][
														toChartYears[0].length -
															1
													]
												}{" "}
												by{" "}
												{
													segmentation2[
														segmentationIndex
													]
												}{" "}
												US$ {valueDisplayOption}
											</h6>

											{loadingStep >= 2 ? (
												<AnyChartFigure1Versus
													years={toChartYears[0]}
													data={
														toChartData[
															segmentationIndex
														][segmIndex]
													}
													total={
														totals[
															segmentationIndex
														][segmIndex][0]
													}
													segmentationItems={
														toChartSegmItems[
															segmentationIndex
														][segmIndex]
													}
													output={output}
													segmentationVersusTitles={
														segmentationVersusTitles[
															segmentationIndex
														][0]
													}
													topicName={topicName}
													valueDisplayOption={
														valueDisplayOption
													}
													segmentationName={
														segmentation1[
															segmentationIndex
														]
													}
													segmentationName2={
														segmentation2[
															segmentationIndex
														]
													}
													segmentation1ItemsName={
														segmentationTitles[
															segmentationIndex
														][segmIndex]
													}
												/>
											) : (
												<h3>
													{/* TODO change loading */}
													<div>
														<div className="row mt-1">
															<div
																className="spinner-border gutter-none m-5"
																role="status"
															>
																<span className="visually-hidden">
																	Loading...
																</span>
															</div>
														</div>
														<div className="row">
															<span className="text-primary">
																Loading data...
															</span>
														</div>
													</div>
												</h3>
											)}
										</div>

										<hr />

										{/* <h1>Cambios aca</h1> */}

										{/* CHART ST002  */}
										<div
											style={{
												display: "grid",
												gridTemplateColumns: "100%",
											}}
										>
											{/* TODO LOADING STEPS */}
											{loadingStep >= 3 ? (
												<div style={{ width: "100%" }}>
													<h6>
														{" "}
														Figure{" "}
														{segmentationIndex + 2}.
														{getIndex(
															segmentationIndex
														)}{" "}
														{
															segmentationTitles[
																segmentationIndex
															][segmIndex]
														}{" "}
														Market Forecast to{" "}
														{
															toChartYears[0][
																toChartYears[0]
																	.length - 1
															]
														}{" "}
														by{" "}
														{
															segmentation2[
																segmentationIndex
															]
														}{" "}
														US$ {valueDisplayOption}
													</h6>

													<div
														style={{
															width: "100%",
															height: "500px",
														}}
													>
														{compareFYFL(
															toChartData[
																segmentationIndex
															][segmIndex][0],
															toChartData[
																segmentationIndex
															][segmIndex][
																toChartData[
																	segmentationIndex
																][segmIndex]
																	.length - 1
															]
														) ? (
															<div
																style={{
																	height: "100%",
																	width: "100%",
																	display:
																		"flex",
																}}
															>
																<div
																	style={{
																		width: "55%",
																	}}
																>
																	<AnychartSimplifiedPieChart
																		data={
																			toFigureVersus2ChartDataYear[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		year={
																			toFigureVersus2ChartFirstYear
																		}
																		firstYear={
																			toFigureVersus2ChartFirstYear
																		}
																		lastYear={
																			toFigureVersus2ChartLastYear
																		}
																		topicName={
																			topicName
																		}
																		segmentationName={
																			segmentationTitles[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		valueDisplayOption={
																			valueDisplayOption
																		}
																		FYValues={
																			toChartData[
																				segmentationIndex
																			][
																				segmIndex
																			][0]
																		}
																		yearIndex={
																			1
																		}
																		decimalsOption={
																			decimalsOption
																		}
																	></AnychartSimplifiedPieChart>
																</div>
																<div
																	style={{
																		width: "45%",
																	}}
																>
																	<AnychartSimplifiedPieChart
																		data={
																			toFigureVersus2ChartDataYear[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		year={
																			toFigureVersus2ChartFirstYear
																		}
																		firstYear={
																			toFigureVersus2ChartFirstYear
																		}
																		lastYear={
																			toFigureVersus2ChartLastYear
																		}
																		topicName={
																			topicName
																		}
																		segmentationName={
																			segmentationTitles[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		valueDisplayOption={
																			valueDisplayOption
																		}
																		FYValues={
																			toChartData[
																				segmentationIndex
																			][
																				segmIndex
																			][
																				toChartData[
																					segmentationIndex
																				][
																					segmIndex
																				]
																					.length -
																					1
																			]
																		}
																		yearIndex={
																			2
																		}
																		decimalsOption={
																			decimalsOption
																		}
																	></AnychartSimplifiedPieChart>
																</div>
															</div>
														) : (
															<div
																style={{
																	height: "100%",
																	width: "100%",
																	display:
																		"flex",
																}}
															>
																<div
																	style={{
																		width: "45%",
																	}}
																>
																	{/* [segmentationIndex][segmIndex] */}

																	<AnychartSimplifiedPieChart
																		data={
																			toFigureVersus2ChartDataYear[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		year={
																			toFigureVersus2ChartFirstYear
																		}
																		firstYear={
																			toFigureVersus2ChartFirstYear
																		}
																		lastYear={
																			toFigureVersus2ChartLastYear
																		}
																		topicName={
																			topicName
																		}
																		segmentationName={
																			segmentationTitles[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		valueDisplayOption={
																			valueDisplayOption
																		}
																		FYValues={
																			toChartData[
																				segmentationIndex
																			][
																				segmIndex
																			][0]
																		}
																		yearIndex={
																			1
																		}
																		decimalsOption={
																			decimalsOption
																		}
																	></AnychartSimplifiedPieChart>
																</div>
																<div
																	style={{
																		width: "55%",
																	}}
																>
																	<AnychartSimplifiedPieChart
																		data={
																			toFigureVersus2ChartDataYear[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		year={
																			toFigureVersus2ChartFirstYear
																		}
																		firstYear={
																			toFigureVersus2ChartFirstYear
																		}
																		lastYear={
																			toFigureVersus2ChartLastYear
																		}
																		topicName={
																			topicName
																		}
																		segmentationName={
																			segmentationTitles[
																				segmentationIndex
																			][
																				segmIndex
																			]
																		}
																		valueDisplayOption={
																			valueDisplayOption
																		}
																		FYValues={
																			toChartData[
																				segmentationIndex
																			][
																				segmIndex
																			][
																				toChartData[
																					segmentationIndex
																				][
																					segmIndex
																				]
																					.length -
																					1
																			]
																		}
																		yearIndex={
																			2
																		}
																		decimalsOption={
																			decimalsOption
																		}
																	></AnychartSimplifiedPieChart>
																</div>
															</div>
														)}

														{/*  */}
													</div>
												</div>
											) : (
												<h3>
													{/* TODO change loading  */}
													Loading
												</h3>
											)}
										</div>
									</div>
								))}
							</div>
						)
					)}
				</>
			) : null}
		</>
	);
};
