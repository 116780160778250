import React, { useState, useEffect, useCallback } from "react";

import { PageTitle } from "../components/PageTitle";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { Card, CardHeader, CardBody } from "./../components/Card";
import { DataTable } from "./../components/DataTable";
import { useAuth } from "../authentication/AuthProvider";
import { StudyActions } from "../components/StudyActions";
import { Loading } from "../components/Loading";
import { StudyTitle } from "../components/StudyTitle";

export function Studies() {
	const columns = [
		{
			Header: "Title",
			// Add searchable columns here (in addition to row.study)
			accessor: (row) => [
				row.study,
				row.study.title,
				row.study.cCode,
				row.study.catalogID,
			],
			Cell: ({ value }) => {
				const [study] = value;
				return <StudyTitle study={study} hasAccess={true} />;
			},
		},
		{
			Header: "Publish Date",
			accessor: "publishdate",
		},
		{
			Header: "Actions",
			accessor: "actions",
		},
	];

	const [studies, setStudies] = useState([]);
	const [loaded, setLoaded] = useState(false);

	let auth = useAuth();

	useEffect(() => {
		async function fetchStudies() {
			auth.apiGet("api/studies/get").then((json) => {
				if (!json) {
					return;
				}

				let rows = json.map((s) => {
					var d = new Date(s.datePublished);
					return {
						study: s,
						publishdate: d.toLocaleDateString(),
						actions: <StudyActions study={s} />,
					};
				});

				setStudies(rows);
				setLoaded(true);
			});
		}

		fetchStudies();
	}, [auth]);

	return loaded ? (
		<div>
			<PageTitle>Studies</PageTitle>
			<Breadcrumbs>Purchased Studies</Breadcrumbs>

			{studies.length > 0 ? (
				<Card>
					<CardHeader title="Purchased Studies" />
					<CardBody>
						<DataTable
							filterable
							data={studies}
							columns={columns}
						></DataTable>
					</CardBody>
				</Card>
			) : (
				<div>
					<p>
						No studies available. Please contact our sales
						department.
					</p>
				</div>
			)}
		</div>
	) : (
		<Loading />
	);
}
