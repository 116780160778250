import React from "react";
import { Details } from "./Icons";

export function MarketForecastLink(props) {
	let study = props.study;

	let id = study.catalogID;
	let title = study.title;

	let link = studyLink(title, id);

	return (
		<a href={link} className={props.className} target="_blank">
			<Details /> Details
		</a>
	);
}

const mfBase = "https://www.marketforecast.com";
export function MarketForecastHomepage(props) {
	let text = props.children;
	return (
		<a href={mfBase} target="_blank">
			{text}
		</a>
	);
}

const mf = "https://www.marketforecast.com/reports/";
function studyLink(title, id) {
	const regex = /[^A-Za-z0-9_]+/gi;
	var safeTitle = title.replace(regex, "-").toLowerCase();

	return mf + safeTitle + "-" + id;
}
