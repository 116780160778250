import React, { Component, useState } from "react";

export function Accordion(props) {
	const [state, setState] = useState({
		collapsed: props.collapsed,
	});

	function handleToggle() {
		setState((prevState) => ({
			collapsed: !prevState.collapsed,
		}));
	}

	return (
		<div>
			<div
				className={state.collapsed ? "nav-link collapsed" : "nav-link"}
				onClick={handleToggle}
			>
				{props.icon && (
					<div className="sb-nav-link-icon">
						<i className={"fas " + props.icon}></i>
					</div>
				)}
				{props.title}
				<div className="sb-sidenav-collapse-arrow">
					<i className="fas fa-angle-down"></i>
				</div>
			</div>
			<div className={state.collapsed ? "collapse" : ""}>
				<nav className="sb-sidenav-menu-nested nav">
					{props.children}
				</nav>
			</div>
		</div>
	);
}
