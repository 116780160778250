import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

import "anychart/dist/js/anychart-core.min.js";
import "anychart/dist/js/anychart-bundle.min.js";
import "anychart/dist/js/anychart-base.min.js";

export const AnyChartFigure1 = ({
	years,
	data,
	total,
	segmentationItems,
	output,
	segmentationVersusTitles,
	topicName,
	segmentationName,
	valueDisplayOption,
}) => {
	const chartContainerRef = useRef(null);

	useEffect(() => {
		let chart = anychart;

		// Construct Data for set
		const totalCopy = [...total];
		totalCopy.shift();
		totalCopy.pop();

		for (let i = 0; i < totalCopy.length; i++) {
			const num = parseFloat(totalCopy[i]);
			totalCopy[i] = isNaN(num) ? totalCopy[i] : num;
		}

		const forSetData = years.map((year, index) => {
			return [year, ...data[index], totalCopy[index]];
		});

		// Set Data
		let dataSet = chart.data.set(forSetData);

		// Create Bar chart
		let column = chart.column();

		// APPLY THEME
		chart.theme("marketForecast");

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		// CREDITS
		const currentYear = new Date().getFullYear();
		column
			.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		// Force chart to stack values by Y scale
		column.yScale().stackMode("value");

		// Title
		column
			.title()
			.enabled(true)
			.useHtml(true)
			.padding([0, 0, 10, 0])
			.text(
				`<span style="font-size: 16px;font-weight:bold;">${topicName} Market to ${
					years[years.length - 1]
				} by ${segmentationName}</span>` +
					'<br/><span style="color:#929292; font-size: 12px;">' +
					`Market Forecast ${years[0]} - ${
						years[years.length - 1]
					}</span>`
			);

		// Set chart margin settings
		column.padding(20, 5, 5, 5);

		// Grid settings
		column.yGrid(true).xGrid(false).xMinorGrid(false).yMinorGrid(true);

		// Set chart legend settings
		column.legend().enabled(true).padding({ top: 10, bottom: 30 });
		column.legend().position("bottom");
		column.legend().itemsLayout("horizontal-expandable");

		column
			.yAxis(0)
			.title(`Market size in US$ ${valueDisplayOption}`)
			.title()
			.padding(10)
			.fontColor("#385D8A");

		column
			.yAxis(0)
			.labels()
			.format(function () {
				return anychart.format.number(this.value, {
					groupsSeparator: ",",
				});
			});

		// Helper function to setup label settings for all series
		const setupSeriesLabels = (series, item) => {
			series.name(item.description).stroke("1 #fff 1");
			series.hovered().stroke("1 #fff 1");

			series.normal().stroke("#ffffff", 1, "", "");
			series.hovered().stroke("#ffffff", 1, "", "");
			series.selected().stroke("#ffffff", 1, "", "");

			// Set opacity for different states,
			series.normal().fill(function () {
				return this.sourceColor + " 1"; // Keep color with opacity 1
			});

			series.hovered().fill(function () {
				return this.sourceColor + " 0.8"; // Keep color with opacity 0.8
			});

			series.selected().fill(function () {
				return this.sourceColor + " 0.6"; // Keep color with opacity 0.6
			});

			series.legendItem().text(item.name);
		};

		// Create dynamic series
		segmentationItems.forEach((item, index) => {
			const segmSeriesData = dataSet.mapAs({ x: 0, value: index + 1 });
			const series = column.column(segmSeriesData);
			setupSeriesLabels(series, item);
		});

		// Set Global toolTip styles
		column.tooltip().title().fontColor("#FFFFFF"); // set tooltip text color
		column.tooltip().title().fontSize(14); // set tooltip fontSize
		column.tooltip().fontColor("#FFFFFF"); // set tooltip text Color
		column.tooltip().fontSize(12); // Set tooltip font size
		column.tooltip().padding(10); // Set tooltip padding

		// Customize the tooltip title and content
		column
			.tooltip()
			.titleFormat(function () {
				return "Market Forecast: " + this.points[0].x;
			})
			.format(function () {
				return (
					this.seriesName +
					" : " +
					anychart.format.number(this.value, {
						groupsSeparator: ",",
						decimalSeparator: ".",
					}) +
					` US$ ${valueDisplayOption}`
				);
			});

		// Set interactivity hover
		column.interactivity().hoverMode("by-y");
		column.tooltip().valuePrefix("$").displayMode("union");

		// Marker
		const totalSeriesData = dataSet.mapAs({
			x: 0,
			value: forSetData[0].length - 1,
		}); // get length of first index
		const totalSeriesForecast = column.marker(totalSeriesData);
		totalSeriesForecast.name("Total"); // Rename the totalSeriesForecast
		totalSeriesForecast.normal().type("circle");

		// Add Labels to totalSeriesForecast with format
		totalSeriesForecast
			.labels()
			.enabled(true)
			.format(function () {
				return anychart.format.number(this.value, {
					groupsSeparator: ",",
				});
			})
			.anchor("center-bottom")
			.offsetY(10) // Adjust offsetY to position the label above the column
			.padding(5)
			.background()
			.enabled(true)
			.fill("#FFFFFF"); // Add Background color

		column.container(chartContainerRef.current).draw();

		// contextMenu
		column.contextMenu().itemsFormatter(function (items) {
			// modify save as png
			items["save-chart-as"].subMenu["save-chart-as-png"].action =
				function () {
					column.saveAsPng({
						width: 1000,
						height: 1000,
						quality: 0.3,
						filename:
							"ST01_" +
							topicName +
							"_Market_to_" +
							years[years.length - 1] +
							"_by_" +
							segmentationName,
					});
				};
			// modify save as jpg
			items["save-chart-as"].subMenu["save-chart-as-jpg"].action =
				function () {
					column.saveAsJpg({
						width: 1000,
						height: 1000,
						quality: 0.3,
						forceTransparentWhite: false,
						filename:
							"ST01_" +
							topicName +
							"_Market_to_" +
							years[years.length - 1] +
							"_by_" +
							segmentationName,
					});
				};

			// modify save as svg
			items["save-chart-as"].subMenu["save-chart-as-svg"].action =
				function () {
					column.saveAsSvg({
						width: 1000,
						height: 1000,
						filename:
							"ST01_" +
							topicName +
							"_Market_to_" +
							years[years.length - 1] +
							"_by_" +
							segmentationName,
					});
				};

			// modify save as pdf
			items["save-chart-as"].subMenu["save-chart-as-pdf"].action =
				function () {
					column.saveAsPdf({
						paperSize: "a4",
						landscape: true,
					});
				};
			delete items["about"];
			delete items["share-with"];
			return items;
		});

		chart.exports.filename(
			"ST01_" +
				topicName +
				"_Market_to_" +
				years[years.length - 1] +
				"_by_" +
				segmentationName
		);

		return () => {
			column.dispose();
		};
	}, [
		years,
		data,
		segmentationItems,
		total,
		valueDisplayOption,
		topicName,
		segmentationName,
	]);

	return (
		<div
			ref={chartContainerRef}
			style={{ width: "600px", height: "450px" }}
		></div>
	);
};
