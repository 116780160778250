import React, { useEffect, useState } from "react";
import {
	useNavigate,
	useSearchParams,
	Link,
	useLocation,
} from "react-router-dom";
import { MarketForecastHomepage } from "../components/MarketForecastLink";
import { useNotifications } from "../notifications/Notifications";
import { service } from "../services/ForgotPassword";

export function CreatePassword() {
	let notifications = useNotifications();
	let navigate = useNavigate();
	let location = useLocation();

	const [emlAddress, setEml] = useState("");
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const address = searchParams.get("e");
		if (address) {
			setEml(address);

			searchParams.delete("e");
			setSearchParams(searchParams);
		}
	}, [searchParams, setSearchParams, setEml]);

	function handleSubmit(event) {
		event.preventDefault();

		notifications.promise(
			service.ForgotPassword(emlAddress, () => {
				navigate("/resetpassword");
			})
		);
	}

	function handleChange(event) {
		setEml(event.target.value);
	}

	return (
		<div className="col-lg-5">
			<div className="card shadow-lg border-0 rounded-lg mt-5">
				<div className="card-header">
					<h3 className="text-center font-weight-light my-4">
						Create Password
					</h3>
				</div>
				<div className="card-body">
					<div className="small mb-3 text-muted">
						Enter your email address and we will send you a code to
						reset your password
					</div>
					<form onSubmit={handleSubmit}>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="emailAddress"
								type="email"
								placeholder="Email address"
								value={emlAddress}
								onChange={handleChange}
								required
							/>
							<label htmlFor="emailAddress">Email address</label>
						</div>
						<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
							<Link to="/login" className="small">
								Return to login
							</Link>
							<button type="submit" className="btn btn-dark">
								Create password
							</button>
						</div>
					</form>
				</div>

				<div className="card-footer text-center py-3">
					<div className="small">
						<MarketForecastHomepage>
							Need an account? Sign up on MarketForecast.com!
						</MarketForecastHomepage>
					</div>
				</div>
			</div>
		</div>
	);
}
