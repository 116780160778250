import React, { useState } from "react";
import { Outlet } from "react-router";
import { Sidebar } from "./Sidebar";
import { Link } from "react-router-dom";
import { useAuth } from "../authentication/AuthProvider";

// todo: inclusion of the footer

export default function Layout(props) {
	let auth = useAuth();

	const [state, setState] = useState({
		collapsed: props.fullScreen,
	});

	function handleToggleSidebar(event) {
		event.preventDefault();

		setState({
			collapsed: !state.collapsed,
		});
	}

	let paddingClass = "";
	if (!props.fullScreen) {
		paddingClass = "px-4";
	}

	return (
		<div
			className={
				state.collapsed
					? "sb-nav-fixed sb-sidenav-toggled"
					: "sb-nav-fixed"
			}
		>
			<nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
				{/* Navbar Brand */}
				<Link to="/" className="navbar-brand ps-3">
					MF Intelligencebase
				</Link>
				{/* Sidebar Toggle */}
				<button
					className="sidebarToggle btn btn-link btn-sm order-lg-0 me-4 me-lg-0 order-1"
					onClick={handleToggleSidebar}
				>
					<i className="fas fa-bars"></i>
				</button>
				{/* Navbar Search */}
				<form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-md-0 my-2">
					{/*
							<div className="input-group">
								<input className="form-control" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
								<button className="btn btn-primary" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
							</div>
							*/}
				</form>
				{/* Navbar */}
				<ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
					<li className="nav-item dropdown">
						<a
							className="navbarDropdown nav-link dropdown-toggle"
							href="#"
							role="button"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							<i className="fas fa-user fa-fw"></i>
						</a>
						<ul
							className="dropdown-menu dropdown-menu-end"
							aria-labelledby="navbarDropdown"
						>
							<li>
								<button
									className="dropdown-item"
									onClick={auth.signout}
								>
									Logout
								</button>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
			<div className="LayoutSidenav">
				<div className="LayoutSidenav_nav">
					<Sidebar />
				</div>
				<div className="LayoutSidenav_content">
					<div
						className={"container-fluid " + paddingClass}
						style={
							props.fullScreen
								? { paddingLeft: "0", paddingRight: "0" }
								: {}
						}
					>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
}
