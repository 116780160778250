import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../authentication/AuthProvider";
import { useNavigate } from "react-router";
import { useNotifications } from "../notifications/Notifications";
import { useTrackUserAction } from "../services/InteractionTrackingService";

export function LoginConfirmation() {
	const trackUserAction = useTrackUserAction();
	let navigate = useNavigate();
	let auth = useAuth();
	let notifications = useNotifications();

	const [trustDevice, setTrustDevice] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();

		let formData = new FormData(event.currentTarget);
		let code = formData.get("inputCode");

		auth.confirmSignin(code, trustDevice, () => {
			// trackUserAction(1);
			// setTimeout(() => {
			// 	navigate("/", { replace: true });
			// }, 500);
		});
	}

	return (
		<div className="col-lg-5">
			<div className="card shadow-lg border-0 rounded-lg mt-5">
				<div className="card-header">
					<h3 className="text-center font-weight-light my-4">
						Verify it's you
					</h3>
				</div>
				<div className="card-body">
					<form onSubmit={handleSubmit}>
						<div className="form-floating mb-3">
							<p>
								We sent a verification code to your email.{" "}
								<br />
								Enter the code from the email in the field
								below.
							</p>
						</div>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="inputCode"
								type="text"
								placeholder="inputCode"
								required
							/>
							<label for="inputCode">Verification Code</label>
						</div>
						<div className="form-check mb-3">
							<input
								className="form-check-input"
								name="inputRemember60"
								type="checkbox"
								checked={trustDevice}
								onChange={() => setTrustDevice(!trustDevice)}
							/>
							<label
								className="form-check-label"
								for="inputRemember60"
							>
								Trust this device for 60 days
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
							<Link to="/login" className="small">
								No code? Go back and retry.
							</Link>
							<button type="submit" className="btn btn-dark">
								Verify code
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
