import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import { Breadcrumbs } from "./../components/Breadcrumbs";
import { PageTitle } from "./../components/PageTitle";
import { Card, CardHeader, CardBody } from "./../components/Card";
import { DataTable } from "./../components/DataTable";
import { useAuth } from "../authentication/AuthProvider";
import { MarketForecastLink } from "../components/MarketForecastLink";
import { Details } from "../components/Icons";
import { StudyActions } from "../components/StudyActions";
import { StudyTitle } from "../components/StudyTitle";
import { Loading } from "../components/Loading";

import { useTrackUserAction } from "../services/InteractionTrackingService";

export function Catalog() {
	const trackUserAction = useTrackUserAction();
	let { tagID } = useParams();
	const columns = [
		{
			Header: "Title",
			// Add searchable columns here (in addition to row.study)
			accessor: (row) => [
				row.study,
				row.hasAccess,
				row.study.title,
				row.study.cCode,
				row.study.catalogID,
			],
			Cell: ({ value }) => {
				const [study, hasAccess] = value;
				return <StudyTitle study={study} hasAccess={hasAccess} />;
			},
		},
		{
			Header: "Publish Date",
			accessor: "publishdate",
		},
		{
			Header: "Actions",
			accessor: "actions",
		},
	];

	const [state, setState] = useState({
		loading: true,
		tag: {
			name: "",
		},
		studies: [],
	});

	const auth = useAuth();
	const hasSubscription = auth.user.activeSubscription;

	useEffect(() => {
		if (tagID !== null) trackUserAction(10, null, tagID);
		async function fetchCatalog() {
			let catalogEndpoint = "api/catalog/get/";
			if (tagID) {
				catalogEndpoint += tagID;
			}

			auth.apiGet(catalogEndpoint).then((data) => {
				if (!data) {
					return;
				}

				let studies = data.studies;

				setState({
					loading: false,
					tag: data.tag,
					studies: studies,
				});
			});
		}
		fetchCatalog();
	}, [auth, tagID]);

	return !state.loading ? (
		<div>
			<PageTitle>Studies</PageTitle>
			{renderBreadcrumbs(state.tag)}
			<Card>
				<CardHeader title="Available Studies" />
				<CardBody>
					<DataTable
						filterable
						data={tableData(state.studies)}
						columns={columns}
						rowProps={(row) => ({
							className:
								!hasSubscription && row.original.hasAccess
									? "table-success"
									: "",
						})}
					></DataTable>
				</CardBody>
			</Card>
		</div>
	) : (
		<Loading />
	);
}

function renderBreadcrumbs(tag) {
	if (!tag) {
		return <Breadcrumbs>All Studies</Breadcrumbs>;
	}

	return (
		<Breadcrumbs>
			<Link to="/catalog" className="link-dark text-decoration-none">
				All Studies
			</Link>
			{tag.name}
		</Breadcrumbs>
	);
}

function tableData(studyAccess) {
	return studyAccess.map((s) => {
		let study = s.study;

		let d = new Date(study.datePublished);

		// if no access, link to market forecast in a new tab
		var action = (
			<MarketForecastLink study={study} className="btn btn-dark btn-sm">
				<Details /> Details
			</MarketForecastLink>
		);

		// otherwise this one needs to be a link to the report page
		if (s.hasAccess) {
			action = <StudyActions study={study} />;
		}

		return {
			study: study,
			hasAccess: s.hasAccess,
			publishdate: d.toLocaleDateString(),
			actions: action,
			hasAccess: s.hasAccess,
		};
	});
}
