import React from "react";
import { Outlet } from "react-router";

export function EmptyLayout() {
	return (
		<div className="bg-dark">
			<div id="layoutAuthentication">
				<div id="layoutAuthentication_content">
					<main>
						<div className="container">
							<div className="row justify-content-center">
								<Outlet />
							</div>
						</div>
					</main>
				</div>
				<div id="layoutAuthentication_footer">
					<footer className="py-4 bg-light mt-auto">
						<div className="container-fluid px-4">
							<div className="d-flex align-items-center justify-content-between small">
								<div className="text-muted">
									Copyright &copy; Market Forecast{" "}
									{new Date().getFullYear()}
								</div>
								<div>
									<a href="#">Privacy Policy</a>
									&middot;
									<a href="#">Terms &amp; Conditions</a>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	);
}
