import React from "react";
import { Link } from "react-router-dom";
import { Details, Eye } from "./Icons";

export function StudyActions(props) {
	let study = props.study;
	let studyPage = "/studyDetails/" + study.catalogID;

	return (
		<div className="btn-group">
			<Link to={studyPage} className="btn btn-dark btn-sm">
				<Details /> Details
			</Link>

			{/* <button
				type="button"
				className="btn btn-sm btn-light btn-outline-success dropdown-toggle dropdown-toggle-split"
				data-bs-toggle="dropdown"
				aria-expanded="false"
				data-bs-reference="parent"
			>
				<span className="visually-hidden">Toggle Dropdown</span>
			</button>
			<ul className="dropdown-menu">
				<li>
					<a
						className="dropdown-item"
						onClick={handleDownloadPdf}
						style={{ cursor: "pointer" }}
					>
						<Download /> Download PDF
					</a>
				</li>
				<li>
					<Link to="/login" className="dropdown-item">
						<ExcelDatasheet /> Excel Datasheet
					</Link>
				</li>
			</ul> */}
		</div>
	);
}
