export const authenticationService = {
	SignIn,
	ConfirmSignIn,
	RefreshToken,
};

function SignIn(username, password, callback) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			Username: username,
			Password: password,
		}),
	};

	return fetch("api/access/login", requestOptions)
		.then(handleResponse)
		.then(callback);
}

function ConfirmSignIn(code, trustDevice, callback) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({
			code: code,
			trustDevice: trustDevice,
		}),
	};

	return fetch("api/access/confirmlogin", requestOptions)
		.then(handleResponse)
		.then(callback);
}

function RefreshToken(callback) {
	const requestOptions = {
		method: "POST",
		headers: { "Content-Type": "application/json" },
	};

	return fetch("api/access/refreshtoken", requestOptions)
		.then(handleResponse)
		.then(callback);
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (response.ok) {
			let user = data.result.user;

			if (data.result.jwt) {
				user.jwt = data.result.jwt;
			}

			return user;
		}

		const error = (data && data.message) || response.statusText;
		return Promise.reject(error);
	});
}
