import React from "react";
import { Table } from "./Icons";

function Card(props) {
	return <div className="card mb-4">{props.children}</div>;
}

function CardHeader(props) {
	return (
		<div className="card-header">
			<Table />
			{props.title}
		</div>
	);
}

function CardBody(props) {
	return <div className="card-body">{props.children}</div>;
}

export { Card, CardHeader, CardBody };
