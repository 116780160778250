import React, { useState, useEffect } from "react";
import { useAuth } from "../authentication/AuthProvider";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "../components/PageTitle";
import { Breadcrumbs } from "../components/Breadcrumbs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@awesome.me/kit-524d1d64d7/icons/classic/solid";
import { faChartMixed } from "@awesome.me/kit-524d1d64d7/icons/duotone/solid";

import { SingleSegmentationOutput } from "./Forecasts/SingleSegmentationOutput/SingleSegmentationOutput.page.jsx";
import { VersusSegmentationOutput } from "./Forecasts/VersusSegmentationOutput/VersusSegmentationOutput.page.jsx";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { useTrackUserAction } from "../services/InteractionTrackingService";

export function StudyForecast() {
	const trackUserAction = useTrackUserAction();
	let { catalogID } = useParams();

	const [forecastFiles, setForecastFiles] = useState(null);
	const [descArray, setDescArray] = useState([]);
	const [hasDescArray, setHasDescArray] = useState(false);

	const [study, setStudy] = useState(null);

	const [state, setState] = useState({
		loading: true,
		loadingFiles: true,
		tag: {
			name: "",
		},
	});

	const [valueDisplayOption, setValueDisplayOption] = useState(
		"Value display in..."
	);
	const [decimalsOption, setDecimalsOption] = useState("Decimals...");

	const auth = useAuth();

	const handleDropdownChange = (event) => {
		if (event.target.value === "Value display in...") return;
		setValueDisplayOption(event.target.value); // Set the new value from the dropdown
	};

	const handleDecimalsChange = (event) => {
		if (event.target.value === "Decimals...") return;
		setDecimalsOption(event.target.value); // Set the new value from the dropdown
	};

	const extractRegional = (data) => {
		let regions = [];
		data.map((s) => {
			let region = {
				name: s.name === null ? s.region.regionCode : s.name,
				description:
					s.description === null ? s.region.name : s.description,
				regionId: s.regionId,
				regionName: s.region.name,
				isRegion: true,
			};

			regions.push(region);
		});

		return regions;
	};

	const extractNormalDesc = (data) => {
		let descs = [];
		data.map((s) => {
			let desc = {
				name: s.name,
				description: s.description,
				regionId: null,
				isRegion: false,
			};

			if (
				desc.description !== undefined &&
				desc.description !== null &&
				hasDescArray === false
			) {
				setHasDescArray(true);
			}

			descs.push(desc);
		});
		return descs;
	};

	const trackForecastFiles = () => {
		forecastFiles.idList.map((id) => {
			trackUserAction(7, catalogID, null, id);
		});
	};

	useEffect(() => {
		if (forecastFiles !== null && forecastFiles !== undefined) {
			trackForecastFiles();
		}
	}, [forecastFiles]);

	useEffect(() => {
		async function fetchDetails() {
			const endpoint = "api/study/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}
				setStudy(json);

				setState({
					loading: false,
					tag: json.tag,
				});

				// const fName = formFileName(json);
				// setFileName(fName);
			});
		}

		async function fetchForecastFiles() {
			const endpoint = "api/forecast/get/";
			if (!catalogID) {
				// handle this
			}

			let url = endpoint + catalogID;
			auth.apiGet(url).then((json) => {
				if (!json) {
					return;
				}

				let extractedDesc = [];
				json.resultList.map((s) => {
					s.singleSegment = JSON.parse(s.singleSegment);
					if (
						s.singleSegment[0].segmentations[0].isRegional === true
					) {
						const extracted = extractRegional(
							s.singleSegment[0].segmentations[0]
								.segmentationItems
						);
						extractedDesc.push(extracted);
					} else {
						const extracted = extractNormalDesc(
							s.singleSegment[0].segmentations[0]
								.segmentationItems
						);

						extractedDesc.push(extracted);
					}
					s.versusSegment = JSON.parse(s.versusSegment);
				});
				setDescArray(extractedDesc);
				setForecastFiles(json);
				setState({
					loadingFiles: false,
				});
			});
		}

		fetchForecastFiles();
		fetchDetails();
	}, [auth, catalogID]);

	return study === null ? null : (
		<>
			<PageTitle>{study.title}</PageTitle>
			{renderBreadcrumbs(study.title, catalogID)}

			<hr></hr>
			<h2 className="mt-2">
				<FontAwesomeIcon icon={faChartMixed} size="2x" /> Forecast:
				Global and Segmented Forecasts Data & Visualization
			</h2>

			{forecastFiles === null ? null : (
				<>
					<Tabs className="mt-4">
						<TabList>
							{forecastFiles.resultList.map((f, index) => (
								<Tab key={index}>By {f.segment}</Tab>
							))}
						</TabList>

						{forecastFiles === null ? (
							<div>No data available</div>
						) : (
							<div>
								{forecastFiles.resultList.map((f, index) => (
									<TabPanel key={index}>
										{forecastFiles.resultList.map(
											(seg, segIndex) => (
												<li
													className="list-group-item list-group-item-action list-group-item-secondary"
													key={segIndex}
												>
													<a
														className="link-dark"
														href="#"
														onClick={(e) => {
															e.preventDefault();
															const element =
																document.getElementById(
																	`chapter${
																		segIndex +
																		1
																	}`
																);
															if (element) {
																element.scrollIntoView(
																	{
																		behavior:
																			"smooth",
																		block: "start",
																	}
																);
																const yOffset =
																	-60; // Adjust this value as needed
																const y =
																	element.getBoundingClientRect()
																		.top +
																	window.scrollY +
																	yOffset;
																window.scrollTo(
																	{
																		top: y,
																		behavior:
																			"smooth",
																	}
																);
															}
														}}
													>
														{segIndex + 1}. {""}
														{segIndex === 0
															? `${f.singleSegment[0].topicName} Market by ${f.segment} Overview`
															: `${
																	f
																		.singleSegment[0]
																		.topicName
															  } Market ${
																	f.segment
															  } by ${
																	f
																		.versusSegment[
																		segIndex -
																			1
																	]
																		.segmentations[1]
																		.name
															  } `}
													</a>
												</li>
											)
										)}
										{hasDescArray ? (
											<>
												<div className="col-12 col-lg-8 mt-4">
													<h4>
														List of abbreviations
													</h4>
													<dl className="row">
														{descArray.map(
															(
																childArray,
																childArrayIndex
															) =>
																childArray.map(
																	(
																		child,
																		childIndex
																	) => (
																		<React.Fragment
																			key={
																				"frag" +
																				childArrayIndex +
																				childIndex
																			}
																		>
																			{child.isRegional ||
																			child.description ===
																				null ? null : (
																				<>
																					<dt
																						key={
																							"dt" +
																							childArrayIndex +
																							"" +
																							childIndex
																						}
																						className="col-2"
																					>
																						{
																							child.name
																						}
																					</dt>
																					<dd
																						key={
																							"dd" +
																							childArrayIndex +
																							"" +
																							childIndex
																						}
																						className="col-10"
																					>
																						{
																							child.description
																						}
																					</dd>
																				</>
																			)}
																		</React.Fragment>
																	)
																)
														)}
													</dl>
													<hr></hr>
												</div>
											</>
										) : null}

										<div className="row">
											<div className="col-12 col-lg-8 mt-4">
												<div className="row justify-content-end">
													<div className="col-auto col-offset">
														<select
															className="form-select form-select-sm"
															aria-label=".form-select-sm"
															id="values"
															value={
																valueDisplayOption
															}
															onChange={
																handleDropdownChange
															}
														>
															<option>
																Value display
																in...
															</option>
															<option value="millions">
																US$ millions
															</option>
															<option value="billions">
																US$ billions
															</option>
														</select>
													</div>
													<div className="col-auto col-offset">
														<select
															className="form-select form-select-sm"
															aria-label=".form-select-sm"
															id="decimals"
															value={
																decimalsOption
															}
															onChange={
																handleDecimalsChange
															}
														>
															<option>
																Decimals...
															</option>
															<option value="0.">
																0.
															</option>
															<option value=".0">
																.0
															</option>
															<option value=".00">
																.00
															</option>
															<option value=".000">
																.000
															</option>
														</select>
													</div>
												</div>
											</div>
										</div>

										<>
											{f.singleSegment === null ||
											f.singleSegment === undefined ||
											!Array.isArray(f.singleSegment) ||
											f.singleSegment.length === 0 ||
											f.singleSegment[0].segmentations ===
												null ||
											f.singleSegment[0].segmentations ===
												undefined ? (
												<div>
													No data available for single
													segment
												</div>
											) : (
												<SingleSegmentationOutput
													output={f.singleSegment}
													topicName={
														f.singleSegment[0]
															.topicName
													}
													arrayDesc={descArray}
													valueDisplayOption={
														valueDisplayOption ===
														"Value display in..."
															? "millions"
															: valueDisplayOption
													}
													decimalsOption={
														decimalsOption ===
														"Decimals..."
															? "0."
															: decimalsOption
													}
												/>
											)}

											{f.versusSegment === null ||
											f.versusSegment === undefined ||
											!Array.isArray(f.versusSegment) ||
											f.versusSegment.length === 0 ||
											f.versusSegment[0].segmentations ===
												null ||
											f.versusSegment[0].segmentations ===
												undefined ? (
												<div>
													No data available for versus
													segment
												</div>
											) : (
												<>
													<VersusSegmentationOutput
														output={f.versusSegment}
														topicName={
															f.versusSegment[0]
																.topicName
														}
														arrayDesc={descArray}
														valueDisplayOption={
															valueDisplayOption ===
															"Value display in..."
																? "millions"
																: valueDisplayOption
														}
														decimalsOption={
															decimalsOption ===
															"Decimals..."
																? "0."
																: decimalsOption
														}
													/>
												</>
											)}
										</>
									</TabPanel>
								))}
							</div>
						)}
					</Tabs>
				</>
			)}
		</>
	);
}

function renderBreadcrumbs(title, catalogID) {
	if (!title) {
		return <Breadcrumbs>All Studies</Breadcrumbs>;
	}

	return (
		<Breadcrumbs>
			<Link
				key={45}
				to="/catalog"
				className="link-dark text-decoration-none"
			>
				<FontAwesomeIcon icon={faArrowLeft} className="me-2" /> All
				Studies
			</Link>
			<Link
				key={46}
				to={"/studyDetails/" + catalogID}
				className="link-dark text-decoration-none"
			>
				<FontAwesomeIcon icon={faArrowLeft} className="me-2" />
				{title}
			</Link>
			Forecast: Global and Segmented Forecasts Data & Visualization
		</Breadcrumbs>
	);
}
