import React from "react";

function Breadcrumbs(props) {
	function renderBreadcrumbs() {
		let crumbs = props.children;

		if (!Array.isArray(crumbs)) {
			return <BreadcrumbItem active>{crumbs}</BreadcrumbItem>;
		}

		var crumbLength = crumbs.length;

		return crumbs.map((crumb, i) => {
			var props = {
				active: "",
			};
			if (i === crumbLength - 1) {
				props.active = "active";
			}

			return (
				<BreadcrumbItem key={i} {...props}>
					{crumb}
				</BreadcrumbItem>
			);
		});
	}

	return <ol className="breadcrumb mb-4">{renderBreadcrumbs()}</ol>;
}

function BreadcrumbItem(props) {
	let c = "breadcrumb-item " + (props.active ? "active" : "");
	return <li className={c}>{props.children}</li>;
}

export { Breadcrumbs, BreadcrumbItem };
