import React from "react";
import {
	useGlobalFilter,
	useTable,
	useAsyncDebounce,
	usePagination,
} from "react-table";

function DataTable(props) {
	let rowProps = props.rowProps;
	if (rowProps == null) {
		rowProps = (row) => {};
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		page,
		prepareRow,
		state,
		state: { pageIndex, pageSize },
		canPreviousPage,
		canNextPage,
		gotoPage,
		nextPage,
		previousPage,
		pageCount,
		setPageSize,
		setGlobalFilter,
	} = useTable(
		{
			columns: props.columns,
			data: props.data,
			initialState: {
				pageIndex: 0,
				pageSize: 10,
			},
		},
		useGlobalFilter,
		usePagination
	);

	return (
		<div>
			{props.filterable && (
				<div className="dataTable-top">
					<PageSize
						pageSize={state.pageSize}
						setPageSize={setPageSize}
					/>
					<GlobalFilter
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
					/>
				</div>
			)}
			<div className="dataTable-container">
				<table {...getTableProps()} className="dataTable-table">
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps(rowProps(row))}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>
												{cell.render("Cell")}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			{props.filterable && pageCount > 0 && (
				<div className="dataTable-bottom">
					<PaginationInfo
						totalCount={rows.length}
						pageIndex={pageIndex}
						pageSize={pageSize}
					/>
					<Pager
						pageIndex={pageIndex}
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						nextPage={nextPage}
						previousPage={previousPage}
						pageCount={pageCount}
					/>
				</div>
			)}
		</div>
	);
}

function GlobalFilter({ globalFilter, setGlobalFilter }) {
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<div className="dataTable-search">
			<input
				className="dataTable-input"
				placeholder="Search..."
				type="text"
				value={value || ""}
				onChange={(e) => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
			/>
		</div>
	);
}

function PageSize(props) {
	return (
		<div className="dataTable-dropdown">
			<label>
				<select
					className="dataTable-selector"
					value={props.pageSize}
					onChange={(e) => {
						props.setPageSize(Number(e.target.value));
					}}
				>
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="15">15</option>
					<option value="20">20</option>
					<option value="25">25</option>
				</select>{" "}
				entries per page
			</label>
		</div>
	);
}

function PaginationInfo(props) {
	const pageIndex = props.pageIndex;
	const pageSize = props.pageSize;
	const totalCount = props.totalCount;

	let lower = pageSize * pageIndex + 1;
	let upper = Math.min(pageSize * (pageIndex + 1), totalCount);

	return (
		<div className="dataTable-info">
			Showing {lower} to {upper} of {totalCount} entries
		</div>
	);
}

function Pager(props) {
	let pages = [...Array(props.pageCount).keys()];

	return (
		<div className="dataTable-pagination">
			<ul className="dataTable-pagination-list">
				{props.canPreviousPage && (
					<li className="pager">
						<a onClick={() => props.previousPage()}>‹</a>
					</li>
				)}
				{pages.map((n) => {
					var c = n === props.pageIndex ? "active" : "";

					return (
						<li key={n + 1} className={c}>
							<a onClick={() => props.gotoPage(n)}>{n + 1}</a>
						</li>
					);
				})}
				{props.canNextPage && (
					<li className="pager">
						<a onClick={() => props.nextPage()}>›</a>
					</li>
				)}
			</ul>
		</div>
	);
}

export { DataTable };
